import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UserModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/users';
  }

  /**
   * Get details url
   */
  getDetailsUrl(id) {
    return `admin/users/${id}`;
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return `admin/users/${this.data.id}`;
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/user/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  /**
   * Get delete url
   */
  getKycDocumentUrl(id) {
    return `admin/user/${id}/document-submission`;
  }

  async getKycDocument(id) {
    const result = await Api.call(this.getKycDocumentUrl(id), 'GET');
    return result.data;
  }

  // /**
  //  * Set user status to enabled or disabled
  //  * @param {int} userid
  //  * @param {boolean} isEnabled
  //  */
  // async changeUserStatus(userId, isEnabled) {
  //   let url = 'user/disable';
  //   if (isEnabled) {
  //     url = 'user/enable';
  //   }
  //   const result = await Api.call(url, { id: userId });
  //   return result;
  // }

  /**
   * Set user status on Marketing Consent
   * @param {int} userid
   * @param {boolean} isEnabled
   */
  async changeMarketingConsentStatus(userId, isEnabled) {
    const url = 'admin/marketing-consent';
    const result = await Api.call(url, 'POST', {
      userId,
      marketingConsent: isEnabled
    });

    return result;
  }

  async changeUserPhoneNumber(userId, phoneNumber) {
    const result = await Api.call(`admin/users/${userId}`, 'PATCH', {
      phoneNumber
    });
    return result.data;
  }

  /**
   * Get changes list url
   */
  getRequestsUrl() {
    return 'change-request/list';
  }

  async geRequestChanges(id) {
    const result = await Api.call(this.getRequestsUrl(), {
      id
    });
    return result.data;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'vendorReference',
        label: 'Vendor reference',
        sortable: false,
        type: 'field'
      },
      // {
      //   name: 'roles',
      //   label: 'User role',
      //   sortable: false,
      //   type: 'field'
      // },
      {
        name: 'firstName',
        label: 'First Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'additionalData.associateType',
        label: 'User type',
        sortable: false,
        type: 'field'
      },
      {
        name: 'email',
        label: 'E-mail',
        sortable: false,
        type: 'field'
      },
      {
        name: 'kycStatus',
        label: 'KYC Status',
        sortable: false,
        type: 'field'
      },
      // {
      //   name: 'marketingConsent',
      //   label: 'Marketing Consent',
      //   sortable: false,
      //   type: 'field'
      // },
      {
        name: 'userStatus',
        label: 'Disable / Enable User',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }

  /**
   * Get wallet for the user
   *
   * @param {Integer} id Id of the user
   */
  async getAccount(id) {
    const result = await Api.call('users/wallet', {
      id
    });

    return result.data;
  }

  /**
   * Get cards of the user
   *
   * @param {Integer} id Id of the user
   */
  async getCards(id) {
    const result = await Api.call('users/cards', {
      id
    });

    return result.data;
  }

  // /**
  //  * Get payment cards for specific user id
  //  *
  //  * @param {Integer} id Id of the user
  //  */
  // async getPaymentCards(id) {
  //   const result = await Api.call('payment-cards/list', {
  //     userId: id
  //   });
  // }

  /**
   * Get reset password url
   */
  getResetPasswordUrl() {
    return 'users/password-reset';
  }

  async resetPassword(email) {
    const result = await Api.call(this.getResetPasswordUrl(), {
      email
    });
    return result.data;
  }

  /**
   * Get KYC approved reject url
   */
  getKYCApproveRejectUrl(id) {
    return `admin/user/${id}/kyc-status`;
  }

  async changeUserStatusKYC(userId, status) {
    const result = await Api.call(
      this.getKYCApproveRejectUrl(userId),
      'PATCH',
      {
        kycStatus: status
      }
    );
    return result.data;
  }

  async changeUserStatus(userId, status) {
    const result = await Api.call(`admin/user/${userId}/user-status`, 'PATCH', {
      userStatus: status
    });
    return result.data;
  }

  async triggerKYC(id) {
    const result = await Api.call('users/kyc-status/trigger', {
      id
    });
    return result.data;
  }

  /**
   * Trigger eKYC again
   */
  async triggerEKYC(id) {
    const result = await Api.call('users/kyc-status/trigger-ekyc', {
      id
    });
    return result.data;
  }

  async triggerPEP(id) {
    const result = await Api.call('users/kyc-status/trigger-pep', {
      id
    });
    return result.data;
  }

  async triggerSIS(id) {
    const result = await Api.call('users/kyc-status/trigger-sis', {
      id
    });
    return result.data;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      firstName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter first name'
        }
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter last name'
        }
      },
      email: {
        presence: {
          allowEmpty: false,
          message: '^Please enter an email address'
        },
        email: {
          message: '^Please enter a valid email address'
        }
      },
      mobileCountryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country code'
        }
      },
      mobileShort: {
        presence: {
          allowEmpty: false,
          message: '^Please enter mobile number'
        }
      },
      birthDate: {
        presence: {
          allowEmpty: false,
          message: '^Please enter birth date'
        }
      },
      addressOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter address one'
        }
      },
      // addressTwo: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter address two'
      //   }
      // },
      city: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city'
        }
      },
      postCode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter post code'
        }
      },
      countryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country'
        }
      }
    };
  }
}
