/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import AppTable from '@launchpad/components/crud/AppTable';
import { connect } from 'react-redux';
import { AppBadge } from '@launchpad';
import UserModel from '../users/models/UsersModel';
import NewUserWidget from './components/NewUsersWidget';
import NewUsersTrendWidget from './components/NewUsersTrendWidget';
import TransactionsWidget from './components/TransactionsWidget';
import AccountsModel from '../accounts/models/AccountsModel';

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};
class Dashboard extends Component<any, any> {
  _tableComponent: any;
  _tableComponentAccounts: any;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  _renderStatus(status: string) {
    switch (status) {
      case 'verified':
        return <AppBadge text="Verified" icon="check" />;
      case 'rejected':
        return <AppBadge text="Rejected" icon="times" type="danger" />;
      case 'unverified':
        return <AppBadge text="Unverified" icon="times" type="danger" />;
      case 'verification_in_progress':
        return (
          <AppBadge
            text="Verification in progress"
            icon="clock-o"
            type="info"
          />
        );
      case 'pending_frontend_verification':
        return (
          <AppBadge text="Review in progress" icon="clock-o" type="info" />
        );
      case 'referred':
        return (
          <AppBadge
            text="Referred"
            icon="clock-o"
            type="warning"
            style={{ color: 'white' }}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-tachometer" aria-hidden="true" /> Dashboard
        </h1>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <NewUserWidget />
          <NewUsersTrendWidget />
          <TransactionsWidget />
        </div>

        <div className="row">
          <div className="col-12">
            <div className="activated-cards">
              {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
              <div className="dark-blur" />
              <div className="box-header">
                <h2>
                  <i className="fa fa-users" /> Latest Users
                </h2>
                <div className="box-header-action">
                  <Link to="/users" className="button">
                    List all
                  </Link>
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  onInit={(tableComponent: any) =>
                    (this._tableComponent = tableComponent)
                  }
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10
                  }}
                  parseUrl={false}
                  order={[
                    {
                      field: 'id',
                      direction: 'desc'
                    }
                  ]}
                  model={UserModel}
                  renderCell={(
                    column: { name: any },
                    item: { kycStatus: any; additionalData?: any }
                  ) => {
                    switch (column.name) {
                      case 'kycStatus':
                        return this._renderStatus(item.kycStatus);
                      case 'additionalData.associateType':
                        return item.additionalData?.userType
                          ? capitalize(item.additionalData?.userType)
                          : '';
                      default:
                        return undefined;
                    }
                  }}
                  getColumnActions={(member: { id: any }) => {
                    return (
                      <div>
                        <Link
                          to={`/user-details/${member.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="activated-cards">
              {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
              <div className="dark-blur" />
              <div className="box-header">
                <h2>
                  <i className="fa fa-id-card-o" /> Latest Accounts
                </h2>
                <div className="box-header-action">
                  <Link to="/accounts" className="button">
                    List all
                  </Link>
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  onInit={(tableComponent: any) =>
                    (this._tableComponentAccounts = tableComponent)
                  }
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10
                  }}
                  parseUrl={false}
                  order={[
                    {
                      field: 'id',
                      direction: 'desc'
                    }
                  ]}
                  model={AccountsModel}
                  getColumnActions={(member: { id: any }) => {
                    return (
                      <div>
                        <Link
                          to={`/accounts/details/${member.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Dashboard);
