/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';

class KycInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false,
      isLoading: false,
      KYCDocumentsUrls: []
    };
  }

  componentDidMount() {
    this._pullKYCDocuments();
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });
    new UsersModel()
      .changeUserStatusKYC(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  async _pullKYCDocuments() {
    const { id } = this.props.match.params;

    await new UsersModel().getKycDocument(id).then(result => {
      if (result && result.publicUrls) {
        this.setState({
          KYCDocumentsUrls: result.publicUrls
        });
      }
    });
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');
    const userKycStatus = user.kycStatus ? user.kycStatus.toUpperCase() : '';

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user || this.state.isLoading ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <dl>
                          <dt>Change KYC status:</dt>
                          <dd>
                            <select
                              className="option-wrapper"
                              onChange={event =>
                                this.onChangeUserStatusKyc(
                                  user.id,
                                  event.target.value
                                )
                              }
                              value={
                                userKycStatus !== 'UNVERIFIED'
                                  ? userKycStatus
                                  : ''
                              }
                            >
                              <option disabled value="">
                                -- select an option --
                              </option>
                              <option value="PENDING_DATA_RESUBMISSION">
                                Request re-entry of personal details
                              </option>
                              <option value="PENDING_FRONTEND_VERIFICATION">
                                Pending documents
                              </option>
                              <option value="VERIFICATION_IN_PROGRESS">
                                Verification in progress
                              </option>
                              <option value="VERIFIED">Verified</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC Documents Submission</h4>
                        <hr />
                        <div className="d-flex align-items-center justyfy-content-center flex-column">
                          {this.state.KYCDocumentsUrls.length ? (
                            this.state.KYCDocumentsUrls.map((item, index) => {
                              return (
                                <img
                                  key={`document${index}`}
                                  src={item.publicUrl}
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: 300,
                                    margin: 20
                                  }}
                                  alt="document"
                                />
                              );
                            })
                          ) : (
                            <h4 className="m-3">No KYC documents</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(KycInfo);
